$publicPath:'';

.orgSelect {
  /deep/.el-form-item__content {
    display: flex;
    span {
      margin-left: 5px;
      white-space: nowrap;
      color: #0aa665;
      cursor: pointer;
      user-select: none;
    }
  }
}

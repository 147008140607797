$publicPath:'';

@import "./../assets/css/global-variable";
.u-form-status{
    display:flex;
    justify-content: space-between;
    // align-items: flex-end;
    height:36px;
    // padding-left:20px;
    .u-form-status-it{
      height:30px;
      cursor: pointer;
      white-space: nowrap;
      border-bottom:2px solid transparent;
      &[data-active]{
        color:$fc-theme;
        border-bottom:2px solid $fc-theme;
      }
    }
}

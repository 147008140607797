$publicPath:'';

@import "@/assets/css/global-variable";
.g-role-list-box {
  display: flex;
  height: calc(100vh - 125px) !important;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 12px 20px;
  .left {
    width: 20%;
    box-sizing: border-box;
    background: #fafafa;
    padding: 0px 20px;
  }
  .right {
    background-color: #fff;
    width: 80%;
    box-sizing: border-box;
  }
  .treebox {
    box-sizing: border-box;
    background-color: #fff;
    border: solid 1px #dcdfe6;
    border-radius: 10px;
    height: 100%;

    /deep/.el-tree {
      padding: 12px;
      height: 100%;
      .el-tree-node {
        .el-tree-node__children {
          overflow-x: auto;
          overflow-y: none;
        }
        .my-tree-node {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-button--mini.is-circle {
          padding: 5px;
        }
        .el-button + .el-button {
          margin: 0;
        }
      }
    }
  }
  .list-box {
    padding: 0px 20px;
  }
}

$publicPath:'';

.m-table-icons{
  display:flex;
  justify-content: flex-end;
  align-items: center;
  &.exit{
    justify-content: space-between;
  }
  .u-icons{
    width:100px;
    display:flex;
    margin-left: 20px;
    margin-bottom:20px;
    justify-content: space-between;
    align-items: center;
    .u-icon{
      cursor: pointer;
      font-size:18px;
    }
  }
}
.u-checkbox{
  display:block;
  text-align:left;
  width:100%;
  height:30px;
}
.u-pop-content{
  text-align:center;
}
